<template>
  <div class="page-404">
    <div class="content">
      <h1 class="m-0 text-danger">404 Not Found</h1>
      <p class="m-0">
        This page <code>{{ $route.fullPath }}</code> does not exist
      </p>
      <router-link to="/">
        Go to Home
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped lang="scss">
.page-404 {
  height: 100vh;
  background: radial-gradient(#fffcfc 70%, #ffeaea);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #726c6c;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
}

code {
  background-color: #fff;
  padding: 2px 4px;
  border-radius: 4px;
}
</style>
